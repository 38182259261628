export default function GoogleMaps({ data, src }) {
  const hq = data?.components?.googleMaps?.hq;
  return (
    <div className="h-100" style={{ minHeight: "400px" }}>
      <iframe
        className="rounded w-100 h-100"
        src={src ?? hq}
        aria-hidden="false"
        title="Google Maps"
      ></iframe>
    </div>
  );
}
