import PageHeader from "../components/PageHeader";
import Contact from "../components/Contact";

export default function ContactPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.contact?.headline} />
      <Contact data={data} />
    </>
  );
}
