import { useLocation } from "react-router-dom";

export default function NavBar({ data }) {
  const location = useLocation();
  const component = data?.components?.navBar;
  const pages = data?.pages;
  const components = data?.components;

  return (
    <nav
      className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <a
        href="/"
        className="navbar-brand d-flex align-items-center px-4 px-lg-5"
      >
        <h1 className="m-0 text-primary">
          <i className="fa fa-graduation-cap me-3"></i>
          {component?.title}
        </h1>
      </a>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0 align-items-center">
          <a
            href={pages?.home?.url}
            className={`nav-item nav-link ${
              location.pathname === pages?.home?.url ? "active" : ""
            }`}
          >
            <i className="fa fa-home"></i>
            {/* {pages?.home?.name} */}
          </a>
          <a
            href={pages?.about?.url}
            className={`nav-item nav-link ${
              location.pathname === pages?.about?.url ? "active" : ""
            }`}
          >
            {pages?.about?.name}
          </a>
          <a
            href={components?.course?.url}
            className={`nav-item nav-link ${
              location.pathname === components?.course?.url ? "active" : ""
            }`}
          >
            {components?.course?.page}
          </a>
          <a
            href={components?.service?.url}
            className={`nav-item nav-link ${
              location.pathname === components?.service?.url ? "active" : ""
            }`}
          >
            {components?.service?.page}
          </a>
          <a
            href={components?.product?.url}
            className={`nav-item nav-link ${
              location.pathname === components?.product?.url ? "active" : ""
            }`}
          >
            {components?.product?.page}
          </a>
          <div className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            ></div>
            <div className="dropdown-menu rounded-0 rounded-bottom m-0">
              <a
                href={components?.team?.url}
                className={`dropdown-item ${
                  location.pathname === components?.team?.url ? "active" : ""
                }`}
              >
                {components?.team?.page}
              </a>
              <a
                href={components?.career?.url}
                className={`dropdown-item ${
                  location.pathname === components?.career?.url ? "active" : ""
                }`}
              >
                {components?.career?.page}
              </a>
              {/* <a
                href={pages?.tool?.url}
                className={`dropdown-item ${
                  location.pathname === pages?.tool?.url ? "active" : ""
                }`}
              >
                {pages?.tool?.name}
              </a> */}
              <a
                href={pages?.contact?.url}
                className={`dropdown-item ${
                  location.pathname === pages?.contact?.url ? "active" : ""
                }`}
              >
                {pages?.contact?.name}
              </a>
            </div>
          </div>
        </div>
        <a
          href={pages?.appointment?.url}
          className={`btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block ${
            location.pathname === pages?.appointment?.url ? "active" : ""
          }`}
        >
          {pages?.appointment?.name}
          <i className="fa fa-arrow-right ms-3"></i>
        </a>
      </div>
    </nav>
  );
}
