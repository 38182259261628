import React from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
import CounterUp2 from "./CounterUp2";

export default function Header({ data }) {
  const component = data?.components?.header;

  return (
    <div className="container-fluid header bg-primary p-0 mb-5">
      <div className="row g-0 align-items-center flex-column-reverse flex-lg-row">
        <div className="col-lg-6 p-5 wow fadeIn" data-wow-delay="0.1s">
          <h1 className="display-4 text-white mb-5">{component?.title}</h1>
          <div className="row g-4">
            {component?.cards?.map((item, index) => (
              <div key={index} className="col-sm-4">
                <div className="border-start border-light ps-4">
                  <h2 className="text-white mb-1" data-toggle="counter-up">
                    <CounterUp2 value={item?.value} />
                  </h2>
                  <p className="text-light mb-0">{item?.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 10000 }}
            pagination={{ clickable: true }}
            navigation={true}
            className="header-carousel"
            scrollbar={{ draggable: true }}
          >
            {component?.carousels?.map((item, index) => (
              <SwiperSlide
                key={index}
                className="owl-carousel-item position-relative"
              >
                <img className="img-fluid" src={item?.image} alt={item?.name} />
                <div className="owl-carousel-text">
                  <h1 className="display-1 text-white mb-0">{item?.name}</h1>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
