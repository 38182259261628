export default function ISP({ data }) {
  const component = data?.components?.isp;

  return (
    <div className="container">
      {component?.drives?.map((item) => (
        <iframe
          src={`https://drive.google.com/embeddedfolderview?id=${item}`}
          style={{ width: "100%", height: "600px", border: 0 }}
          title="ISP"
        ></iframe>
      ))}
    </div>
  );
}
