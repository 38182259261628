export default function ContactAddress({ data }) {
  const component = data?.components?.contactAddress;

  return (
    <>
      <div
        className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
        style={{ width: "55px", height: "55px" }}
      >
        <i className="fa fa-map-marker-alt text-primary"></i>
      </div>
      <div className="ms-4">
        <p className="mb-2">{component?.name}</p>
        <h5 className="mb-0">{component?.value}</h5>
      </div>
    </>
  );
}
