import React, { useState } from "react";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

export default function SHA() {
  const [message, setMessage] = useState("");
  const [hashedValue, setHashedValue] = useState("");

  const hashSHA256 = (text) => {
    return CryptoJS.SHA256(text).toString(CryptoJS.enc.Hex);
  };

  const handleChange = (event) => {
    const message = event.target.value;
    setMessage(message);
    setHashedValue(
      message.trim() === "" ? setHashedValue("") : hashSHA256(message)
    );
  };

  const copyToClipboard = () => {
    const hashedMessage = document.getElementById("hashedMessage").innerText;
    navigator.clipboard
      .writeText(hashedMessage)
      .then(() => {
        toast.info("Đã sao chép");
      })
      .catch((err) => {
        console.error("copyToClipboardError", err);
      });
  };

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <p class="d-inline-block border rounded-pill py-1 px-4">
              Cryptographic
            </p>
            <h1 class="mb-4">Secure Hash Algorithm 256-bit</h1>
            <p class="mb-4">
              SHA-256 là một hàm băm mật mã học, tạo ra giá trị băm dài 256 bit
              từ một đầu vào dữ liệu bất kỳ
            </p>
            <p class="mb-4">
              SHA-256 đảm bảo tính duy nhất (mỗi đầu vào sinh ra giá trị băm
              khác nhau) và tính không thể đảo ngược (không thể tái tạo dữ liệu
              ban đầu từ giá trị băm)
            </p>
            <p class="mb-4">
              SHA-256 thường được sử dụng trong các ứng dụng bảo mật như mật
              khẩu, chứng thực, chữ ký số và blockchain
            </p>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="bg-light rounded h-100 d-flex align-items-center p-5">
              <form className="w-100">
                <div class="row g-3">
                  <div class="col-12">
                    <textarea
                      class="form-control border-0"
                      rows="5"
                      value={message}
                      onChange={handleChange}
                      placeholder="Message"
                    ></textarea>
                  </div>
                  {hashedValue && (
                    <div className="col-12">
                      <div className="row">
                        <div className="col-1">
                          <p
                            class="btn"
                            title="Copy to clipboard"
                            onClick={copyToClipboard}
                          >
                            <i class="fas fa-copy"></i>
                          </p>
                        </div>
                        <div className="col-11">
                          <p
                            id="hashedMessage"
                            style={{
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              cursor: "pointer",
                            }}
                            onClick={() => copyToClipboard(hashedValue)}
                          >
                            {hashedValue}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
