import PageHeader from "../components/PageHeader";
import Product from "../components/Product";
import Appointment from "../components/Appointment";
import Testimonial from "../components/Testimonial";

export default function ProductPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.product?.headline} />
      <Product data={data} />
      <Appointment
        data={data}
        appointment={data?.components?.appointment?.course}
      />
      <Testimonial data={data} />
    </>
  );
}
