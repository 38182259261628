import Features from "./Features";

export default function Service({ data }) {
  const component = data?.components?.service;

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <p className="d-inline-block border rounded-pill py-1 px-4">
            {component?.name}
          </p>
          <h1>{component?.title}</h1>
        </div>

        {component?.list?.map(
          (item) =>
            item?.title?.trim() !== "" &&
            item?.features?.length > 0 && (
              <div id={item.id} key={item?.id} className="row g-4 mb-5">
                <h1>{item?.name}</h1>
                <Features data={data} ids={item?.features} />
              </div>
            )
        )}
      </div>
    </div>
  );
}
