import React, { useState } from "react";

export default function CharacterCounter() {
  const [data, setData] = useState("");
  const [character, setCharacter] = useState("");
  const [word, setWord] = useState("");
  const [letter, setLetter] = useState("");
  const [line, setLine] = useState("");

  const handleChange = (event) => {
    const data = event.target.value;
    setData(data);
    countCharacter(data);
    countWord(data);
    countLetter(data);
    countLine(data);
  };

  const countCharacter = (data) => {
    setCharacter(data.length);
  };

  const countWord = (data) => {
    const words = data.trim().split(/\s+/);
    const numWords = words.length === 1 && words[0] === "" ? 0 : words.length;
    setWord(numWords);
  };

  const countLetter = (data) => {
    const cleanData = data.toLowerCase().replace(/[^a-z]/g, "");
    const uniqueLetters = new Set(cleanData);
    setLetter(uniqueLetters.size);
  };

  const countLine = (data) => {
    const lines = data.split(/\r?\n/);
    setLine(lines.length);
  };

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <p class="d-inline-block border rounded-pill py-1 px-4">
              Data Analysis
            </p>
            <h1 class="mb-4">Character Counter</h1>
            <p class="mb-4">
              Công cụ hữu ích và thật dễ dàng khi sử dụng để thống kê và phân
              tích nội dung văn bản với khả năng đếm số lượng ký tự, số lượng
              từ, số lượng chữ cái và số lượng dòng
            </p>
            <p class="mb-4">
              Mang đến cái nhìn chi tiết về dữ liệu, giúp kiểm soát độ dài và
              thông tin văn bản chính xác, giúp tiết kiệm thời gian và nâng cao
              hiệu quả công việc cho mọi người khi làm việc với nội dung số
            </p>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="bg-light rounded h-100 d-flex align-items-center p-5">
              <form className="w-100">
                <div class="row g-3">
                  <div class="col-12">
                    <textarea
                      class="form-control border-0"
                      rows="5"
                      value={data}
                      onChange={handleChange}
                      placeholder="Data"
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3">
                        Character: <span>{character}</span>
                      </div>
                      <div className="col-3">
                        Word: <span>{word}</span>
                      </div>
                      <div className="col-3">
                        Letter: <span>{letter}</span>
                      </div>
                      <div className="col-3">
                        Line: <span>{line}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
