import PageHeader from "../components/PageHeader";
import Course from "../components/Course";
import Appointment from "../components/Appointment";
import Testimonial from "../components/Testimonial";

export default function CoursePage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.course?.headline} />
      <Course data={data} />
      <Appointment
        data={data}
        appointment={data?.components?.appointment?.course}
      />
      <Testimonial data={data} />
    </>
  );
}
