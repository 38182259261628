const calcPriceByPromotion = ({ price, discount }) => {
  discount = Math.max(discount ?? 0, 0);
  return (price * (100 - discount)) / 100;
};

export const calcTotalModulePrice = ({ module }) => {
  const discount = Math.max(module?.discount ?? 0, 0);
  return formatCurrency({
    number: calcPriceByPromotion({ price: module?.price, discount: discount }),
  });
};

export const calcTotalCoursePrice = ({ data, course }) => {
  const allCredits = data?.modules?.credits;
  const idCredits = course.credits;
  const price = course?.price;
  const discount = Math.max(course?.discount ?? 0, 0);

  if (Number.isInteger(price) && price > 0) {
    return formatCurrency({
      number: calcPriceByPromotion({ price: price, discount: discount }),
    });
  } else if (typeof price === "string" && price.length > 0) {
    return price;
  } else {
    return formatCurrency({
      number: allCredits
        ?.filter((item) => idCredits.includes(item.id))
        .reduce((total, item) => {
          return Number.isInteger(item.price) && item.price > 0
            ? total +
                calcPriceByPromotion({
                  price: calcPriceByPromotion({
                    price: item.price,
                    discount: item.discount,
                  }),
                  discount: discount,
                })
            : null;
        }, 0),
    });
  }
};

export const formatCurrency = ({ number }) => {
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  return <span>{formatter.format(number)}</span>;
};

export const formatNumber = ({ number }) => {
  return new Intl.NumberFormat("vi-VN").format(number);
};
