import PageHeader from "../components/PageHeader";
import Feature from "../components/Feature";

export default function FeaturePage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.feature?.headline} />
      <Feature data={data} />
    </>
  );
}
