import PageHeader from "../components/PageHeader";
import Blog from "../components/Blog";
import Appointment from "../components/Appointment";

export default function CareerPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.career?.headline} />
      <Blog posts={data?.components?.career?.posts} />
      <Appointment
        data={data}
        appointment={data?.components?.appointment?.career}
      />
    </>
  );
}
