import Fragment from "./Fragment";
export default function NotFound({ data }) {
  const component = data?.components?.notFound;

  return (
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
            <h1 className="display-1">{component?.name}</h1>
            <h1 className="mb-4">{component?.title}</h1>
            <Fragment description={component?.description} />

            <a className="btn btn-primary rounded-pill py-3 px-5" href="/">
              {data?.master?.goBack}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
