export default function FooterModule({ module }) {
  return (
    <>
      <h5 className="text-light mb-4">{module?.name}</h5>
      {module?.list?.map((item) => (
        <a
          key={item?.id}
          className="btn btn-link"
          href={module.url + "#" + item.id}
        >
          {item?.name}
        </a>
      ))}
    </>
  );
}
