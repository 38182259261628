import Header from "../components/Header";
import About from "../components/About";
import Course from "../components/Course";
import Service from "../components/Service";
import Product from "../components/Product";
import Feature from "../components/Feature";
import Team from "../components/Team";
import Appointment from "../components/Appointment";
import Testimonial from "../components/Testimonial";
import Contact from "../components/Contact";

export default function HomePage({ data }) {
  return (
    <>
      {/* <div>{JSON.stringify(data)}</div> */}
      <Header data={data} />
      <About data={data} />
      <Course data={data} />
      <Service data={data} />
      <Product data={data} />
      <Feature data={data} />
      <Team data={data} />
      <Appointment data={data} />
      <Testimonial data={data} />
      <Contact data={data} />
    </>
  );
}
