import PageHeader from "../components/PageHeader";
import CharacterCounter from "../components/tools/CharacterCounter";
import SHA from "../components/tools/SHA";

export default function ToolPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.tool?.headline} />
      <CharacterCounter />
      <SHA />
    </>
  );
}
