import Fragment from "./Fragment";

export default function Blog({ posts }) {
  return (
    <>
      {posts?.map((post) => (
        <div className="container-xxl py-5">
          <div className="container">
            <div key={post?.id} className="row g-5">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div className="d-flex flex-column">
                  {post?.images?.image_1 && (
                    <img
                      className="img-fluid rounded w-75 align-self-end"
                      src={post?.images?.image_1}
                      alt=""
                    />
                  )}
                  {post?.images?.image_2 && (
                    <img
                      className="img-fluid rounded w-50 bg-white pt-3 pe-3"
                      src={post?.images?.image_2}
                      alt=""
                      style={{ marginTop: "-25%" }}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <p className="d-inline-block border rounded-pill py-1 px-4">
                  {post?.category}
                </p>
                <h1 className="mb-4">{post?.title}</h1>
                <Fragment description={post?.description} />

                <Fragment
                  description={post?.circles}
                  iClassName={"far fa-check-circle text-primary me-3"}
                />

                {post?.submit?.name && (
                  <a
                    className="btn btn-primary rounded-pill py-3 px-5 mt-3"
                    href={post?.submit?.url}
                  >
                    {post?.submit?.name}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
