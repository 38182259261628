import React, { useState } from "react";
import { calcTotalCoursePrice } from "../utils/commonUtil";
import Credit from "./Credit";

export default function Course({ data }) {
  const component = data?.components?.course;
  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <p className="d-inline-block border rounded-pill py-1 px-4">
            {component?.name}
          </p>
          <h1>{component?.title}</h1>
        </div>

        <div className="mb-5">
          {component?.list?.map((item, index) => (
            <div id={index} key={item?.id} className="row g-4">
              <a
                href={"#" + item.id}
                onMouseEnter={() => setHoveredItem(item?.id)}
                onMouseLeave={() => setHoveredItem(null)}
                style={{
                  textDecoration:
                    hoveredItem === item?.id ? "underline" : "none",
                  color: "#0463FA",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <h1>{item?.name}</h1>
              </a>
            </div>
          ))}
        </div>

        {component?.list?.map((item) => (
          <div id={item.id} key={item?.id} className="row g-4 mb-5">
            <h1>{item?.name}</h1>
            <h3>{calcTotalCoursePrice({ data: data, course: item })}</h3>
            <Credit data={data} ids={item?.credits} />
          </div>
        ))}
      </div>
    </div>
  );
}
