export default function FooterContact({ data }) {
  const contactAddress = data?.components?.contactAddress;
  const contactPhone = data?.components?.contactPhone;
  const contactEmail = data?.components?.contactEmail;

  return (
    <>
      <h5 className="text-light mb-4">{contactAddress?.name}</h5>
      <p className="mb-2">
        <i className="fa fa-map-marker-alt me-3"></i>
        {contactAddress?.value}
      </p>
      <p className="mb-2">
        <a href={`tel:${contactPhone?.value?.replace(/\s+/g, "")}`}>
          <i className="fa fa-phone-alt me-3"></i>
          {contactPhone?.value}
        </a>
      </p>
      <p
        className="mb-2 d-flex align-items-center"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        <a
          href={`mailto:${contactEmail?.value}`}
          style={{ display: "inline-block" }}
        >
          <i className="fa fa-envelope me-3"></i>
        </a>
        <a
          href={`mailto:${contactEmail?.value}`}
          style={{ display: "inline-block" }}
        >
          {contactEmail?.value}
        </a>
      </p>
      <div className="d-flex pt-2">
        <a
          className="btn btn-outline-light btn-social rounded-circle"
          href={data?.master?.socialNetwork?.facebook}
          target="blank"
        >
          <i className="fab fa-facebook-f"></i>
        </a>
        <a
          className="btn btn-outline-light btn-social rounded-circle"
          href={data?.master?.socialNetwork?.youtube}
          target="blank"
        >
          <i className="fab fa-youtube"></i>
        </a>
      </div>
    </>
  );
}
