import PageHeader from "../components/PageHeader";
import Testimonial from "../components/Testimonial";

export default function TestimonialPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.testimonial?.headline} />
      <Testimonial data={data} />
    </>
  );
}
