export default function Team({ data }) {
  const component = data?.components?.team;

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <p className="d-inline-block border rounded-pill py-1 px-4">
            {component?.name}
          </p>
          <h1>{component?.title}</h1>
        </div>
        <div className="row g-4">
          {component?.list?.map((item) => (
            <div
              key={item?.id}
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  {item?.image && (
                    <img
                      className="img-fluid"
                      src={item?.image}
                      alt={item?.name}
                    />
                  )}
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>{item?.name}</h5>
                  <p className="text-primary">{item?.department}</p>
                  <div className="team-social text-center">
                    <p className="btn btn-square">
                      <i className="fa fa-heart"></i>
                    </p>
                    <p className="btn btn-square">
                      <i className="fa fa-certificate"></i>
                    </p>
                    <p className="btn btn-square">
                      <i className="fa fa-star"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
