import ContactEmail from "./ContactEmail";
import ContactPhone from "./ContactPhone";
import Fragment from "./Fragment";
import GoogleForms from "./GoogleForms";

export default function Appointment({ data, appointment }) {
  return (
    <div id="appointment" className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <p className="d-inline-block border rounded-pill py-1 px-4">
              {appointment?.name}
            </p>
            <h1 className="mb-4">{appointment?.title}</h1>
            <Fragment description={appointment?.description} />

            <div className="bg-light rounded d-flex align-items-center p-5 mb-4">
              <ContactPhone data={data} />
            </div>
            <div className="bg-light rounded d-flex align-items-center p-5">
              <ContactEmail data={data} />
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <GoogleForms src={appointment?.googleForms} />
          </div>
        </div>
      </div>
    </div>
  );
}
