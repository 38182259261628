import React, { useEffect, useRef } from "react";
import CounterUp from "counterup2";

export default function CounterUp2({ value }) {
  const counterRef = useRef(null);

  useEffect(() => {
    const counter = counterRef.current;
    if (counter) {
      CounterUp(counter, { duration: 2000, delay: 10 });
    }
  }, []);

  return (
    <div>
      <div className="counter" ref={counterRef}>
        {value}
      </div>
    </div>
  );
}
