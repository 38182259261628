import FooterContact from "./FooterContact";
import FooterCourse from "./FooterCourse";
// import FooterNewsletter from "./FooterNewsletter";
import FooterProduct from "./FooterProduct";
// import FooterQuickLinks from "./FooterQuickLinks";
import FooterService from "./FooterService";

export default function Footer({ data }) {
  const component = data?.components?.footer;

  return (
    <div
      className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <FooterContact data={data} />
          </div>
          <div className="col-lg-3 col-md-6">
            <FooterCourse data={data} />
          </div>
          <div className="col-lg-3 col-md-6">
            {/* <FooterQuickLinks data={data} /> */}
            <FooterService data={data} />
          </div>
          <div className="col-lg-3 col-md-6">
            {/* <FooterNewsletter data={data} /> */}
            <FooterProduct data={data} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              <i>&copy; 2024</i>
              <i className="border-bottom">{component?.siteName}</i>{" "}
              <i>{component?.allRightReserved}</i>
            </div>
            <div className="col-md-6 text-center text-md-end">
              {component?.design?.title}{" "}
              <a className="border-bottom" href={component?.design?.url}>
                {component?.design?.by}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
