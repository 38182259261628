import PageHeader from "../components/PageHeader";
import Blog from "../components/Blog";

export default function BlogPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.blog?.headline} />
      <Blog data={data?.components?.blog?.posts} />
    </>
  );
}
