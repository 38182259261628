import PageHeader from "../components/PageHeader";
import Appointment from "../components/Appointment";

export default function AppointmentPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.appointment?.headline} />
      <Appointment
        data={data}
        appointment={data?.components?.appointment?.course}
      />
    </>
  );
}
