import { Routes, Route } from "react-router-dom";
import TopBar from "./components/TopBar";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import CoursePage from "./pages/CoursePage";
import ServicePage from "./pages/ServicePage";
import ProductPage from "./pages/ProductPage";
import FeaturePage from "./pages/FeaturePage";
import TeamPage from "./pages/TeamPage";
import AppointmentPage from "./pages/AppointmentPage";
import TestimonialPage from "./pages/TestimonialPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFoundPage";
import BlogPage from "./pages/BlogPage";
import CareerPage from "./pages/CareerPage";
import ISPPage from "./pages/ISPPage";
import ToolPage from "./pages/ToolPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import initialData from "./data/init";
// import useFetchHook from "./hooks/useFetchHook";

function App() {
  // const { data, loading, error } = useFetchHook("/init");
  const data = initialData();
  const pages = data?.pages;
  const components = data?.components;

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;

  return (
    <div>
      {/* <div>{JSON.stringify(data)}</div> */}
      <TopBar data={data} />
      <NavBar data={data} />
      <Routes>
        <Route path="/" element={<HomePage data={data} />}></Route>
        <Route
          path={pages?.about?.url}
          element={<AboutPage data={data} />}
        ></Route>
        <Route
          path={components?.course?.url}
          element={<CoursePage data={data} />}
        ></Route>
        <Route
          path={components?.service?.url}
          element={<ServicePage data={data} />}
        ></Route>
        <Route
          path={components?.product?.url}
          element={<ProductPage data={data} />}
        ></Route>
        <Route
          path={components?.feature?.url}
          element={<FeaturePage data={data} />}
        ></Route>
        <Route
          path={components?.team?.url}
          element={<TeamPage data={data} />}
        ></Route>
        <Route
          path={pages?.appointment?.url}
          element={<AppointmentPage data={data} />}
        ></Route>
        <Route
          path={pages?.testimonial?.url}
          element={<TestimonialPage data={data} />}
        ></Route>
        <Route
          path={pages?.contact?.url}
          element={<ContactPage data={data} />}
        ></Route>
        <Route path="/blog" element={<BlogPage data={data} />}></Route>
        <Route
          path={components?.career?.url}
          element={<CareerPage data={data} />}
        ></Route>
        <Route path="/isp" element={<ISPPage data={data} />}></Route>
        <Route
          path={pages?.tool?.url}
          element={<ToolPage data={data} />}
        ></Route>
        <Route path="*" element={<NotFoundPage data={data} />}></Route>
      </Routes>
      <Footer data={data} />
      <ToastContainer />
    </div>
  );
}

export default App;
