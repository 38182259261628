import PageHeader from "../components/PageHeader";
import NotFound from "../components/NotFound";

export default function NotFoundPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.notFound?.headline} />
      <NotFound data={data} />
    </>
  );
}
