import Fragment from "./Fragment";

export default function About({ data }) {
  const component = data?.components?.about;

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="d-flex flex-column">
              {component?.images?.image_1 && (
                <img
                  className="img-fluid rounded w-75 align-self-end"
                  src={component?.images?.image_1}
                  alt=""
                />
              )}
              {component?.images?.image_2 && (
                <img
                  className="img-fluid rounded w-50 bg-white pt-3 pe-3"
                  src={component?.images?.image_2}
                  alt=""
                  style={{ marginTop: "-25%" }}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <p className="d-inline-block border rounded-pill py-1 px-4">
              {component?.name}
            </p>
            <h1 className="mb-4">{component?.title}</h1>
            <Fragment description={component?.description} />

            <Fragment
              description={component?.circles}
              iClassName={"far fa-check-circle text-primary me-3"}
            />

            {component?.submit?.name && (
              <a
                className="btn btn-primary rounded-pill py-3 px-5 mt-3"
                href={component?.submit?.url}
              >
                {component?.submit?.name}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
