import PageHeader from "../components/PageHeader";
import ISP from "../components/ISP";

export default function ISPPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.link?.headline} />
      <ISP data={data} />
    </>
  );
}
