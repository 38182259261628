import ContactAddress from "./ContactAddress";
import ContactEmail from "./ContactEmail";
// import ContactForm from "./ContactForm";
import ContactPhone from "./ContactPhone";
import GoogleForms from "./GoogleForms";
import GoogleMaps from "./GoogleMaps";
import Fragment from "./Fragment";

export default function Contact({ data }) {
  const component = data?.components?.contact;

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-4">
            <div className="h-100 bg-light rounded d-flex align-items-center p-5">
              <ContactAddress data={data} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="h-100 bg-light rounded d-flex align-items-center p-5">
              <ContactPhone data={data} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="h-100 bg-light rounded d-flex align-items-center p-5">
              <ContactEmail data={data} />
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="bg-light rounded p-5">
              <p className="d-inline-block border rounded-pill py-1 px-4">
                {component?.name}
              </p>
              <h1 className="mb-4">{component?.title}</h1>
              <Fragment description={component?.description} />
              {/* <ContactForm data={data} /> */}
            </div>
            <GoogleForms data={data} />
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <GoogleMaps data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
