import PageHeader from "../components/PageHeader";
import About from "../components/About";
import Feature from "../components/Feature";
import Team from "../components/Team";

export default function AboutPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.about?.headline} />
      <About data={data} />
      <Feature data={data} />
      <Team data={data} />
    </>
  );
}
