import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Fragment from "./Fragment";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";

export default function Testimonial({ data }) {
  const component = data?.components?.testimonial;

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <p className="d-inline-block border rounded-pill py-1 px-4">
            {component?.name}
          </p>
          <h1>{component?.title}</h1>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 10000 }}
          pagination={{ clickable: true }}
          navigation
          className="testimonial-carousel"
          scrollbar={{ draggable: true }}
        >
          {component?.list?.map((item) => (
            <SwiperSlide
              key={item?.id}
              className="testimonial-item text-center"
            >
              {item?.image && (
                <img
                  className="img-fluid bg-light rounded-circle p-2 mx-auto mb-4"
                  src={item?.image}
                  alt={item?.name}
                  style={{ width: "100px", height: "100px" }}
                />
              )}
              <div className="testimonial-text rounded text-center p-4">
                <Fragment description={item?.description} />
                <h5 className="mb-1">{item?.name}</h5>
                <span className="fst-italic">{item?.title}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
