export default function initialData() {
  return {
    default: {},
    master: {
      name: "EDX",
      headline: "Enterprise Digital Transformation",
      title: "Học viện đào tạo chuyển đổi số doanh nghiệp",
      url: "/",
      workingHours: "09:00 ~ 16:00 từ Thứ 2 ~ Thứ 6 hàng tuần",
      goBack: "Về trang chủ",
      socialNetwork: {
        facebook: "https://Facebook.com/ChuyenDoiSoDoanhNghiep.edu.vn",
        youtube: "https://YouTube.com/@HocVienChuyenDoiSoDoanhNghiep",
      },
    },
    pages: {
      home: {
        name: "Trang chủ",
        url: "/",
      },
      about: {
        name: "Giới thiệu",
        url: "/gioi-thieu",
      },
      appointment: {
        name: "Đăng ký",
        url: "/dang-ky",
      },
      testimonial: {
        name: "Cảm nhận",
        url: "/cam-nhan",
      },
      contact: {
        name: "Liên hệ",
        url: "/lien-he",
      },
      tool: {
        name: "Công cụ",
        url: "/tool",
      },
      notFound: {
        name: "Không tồn tại",
      },
    },
    components: {
      header: {
        title:
          "Không chỉ là công nghệ, mà còn là việc thay đổi cách thức doanh nghiệp hoạt động và tạo ra giá trị cho khách hàng",
        cards: [
          {
            name: "Giảng viên chuyên gia",
            value: "100",
          },
          {
            name: "Học viên giỏi",
            value: "10.000",
          },
          {
            name: "Doanh nghiệp",
            value: "500",
          },
        ],
        carousels: [
          {
            name: "",
            image: "img/carousel-1.jpg",
          },
          {
            name: "",
            image: "img/carousel-2.jpg",
          },
          {
            name: "",
            image: "img/carousel-3.jpg",
          },
        ],
      },
      pageHeader: {},
      about: {
        name: "Về chúng tôi",
        headline: "Chúng tôi là ai?",
        title: "Tại sao nên lựa chọn chúng tôi? Tìm hiểu ngay!",
        images: {
          image_1: "img/about-1.jpg",
          image_2: "img/about-2.jpg",
        },
        description: `Sở hữu đội ngũ giảng viên là các chuyên gia trong lĩnh vực chuyển đổi số và phân tích dữ liệu, với nhiều năm kinh nghiệm làm việc tại các tập đoàn lớn. Họ sẽ chia sẻ những kiến thức cập nhật và thực tiễn, giúp bạn dễ dàng ứng dụng vào công việc
          Các khóa học được thiết kế để đáp ứng nhu cầu thực tế của ngành công nghiệp, giúp học viên trang bị những kỹ năng cốt lõi về chuyển đổi số và phân tích dữ liệu, sẵn sàng làm việc ngay sau khi hoàn thành khóa học
          Cam kết đồng hành cùng học viên trong suốt quá trình học, cung cấp các tài liệu, hỗ trợ giải đáp thắc mắc và phản hồi liên tục. Mục tiêu của chúng tôi là đảm bảo học viên không chỉ hiểu lý thuyết mà còn áp dụng được kiến thức vào thực tế, giúp bạn đạt được kết quả học tập xuất sắc`,
        circles: [
          "Giảng viên chuyên gia giàu chuyên môn & kinh nghiệm",
          "Chương trình đào tạo thực tiễn hiện đại",
          "Học luôn luôn đi đôi với thực hành",
          "Cam kết đồng hành hỗ trợ học viên lâu dài",
          "Mở rộng cơ hội nghề nghiệp bền vững",
        ],
        submit: {
          name: "Liên hệ",
          url: "/lien-he",
        },
      },
      career: {
        page: "Tuyển dụng",
        headline: "Đi cùng chúng tôi",
        url: "/tuyen-dung",
        posts: [
          {
            id: 1,
            category: "Kinh doanh",
            title: "Nhân viên sales, marketing",
            images: {
              image_1: "img/about-1.jpg",
              image_2: null,
            },
            description: `Tìm kiếm và tiếp cận khách hàng tiềm năng cá nhân từ sinh viên đến kỹ sư và từ nhân viên đến lãnh đạo doanh nghiệp
              Giới thiệu các chương trình đào tạo về chuyển đổi số, kỹ năng số, chuyển đổi công nghệ cho các nhân viên doanh nghiệp
              Liên hệ và làm việc với các doanh nghiệp để tư vấn, thiết kế các chương trình đào tạo phù hợp cho nhân viên
              Xây dựng, phát triển và duy trì bền vững mối quan hệ tốt đẹp lâu dài với khách hàng, đối tác, doanh nghiệp
              Chăm sóc và hỗ trợ học viên trong suốt quá trình tham gia khóa học, đảm bảo học viên đạt được kết quả tốt
              Tổ chức các buổi gặp gỡ, hội thảo, sự kiện giới thiệu chương trình đào tạo cho các công ty và tổ chức`,
            circles: [
              "Mức lương hấp dẫn, hoa hồng cao",
              "Chế độ đãi ngộ & phúc lợi tốt",
              "Cơ hội thăng tiến rõ ràng minh bạch",
              "Môi trường làm việc thân thiện, sáng tạo và chuyên nghiệp",
              "Được đào tạo chuyên sâu về chuyển đổi số & công nghệ",
              "Được đào tạo chuyên sâu về kỹ năng bán hàng & marketing",
            ],
            submit: {
              name: "Ứng tuyển",
              url: "/tuyen-dung#appointment",
            },
          },
          {
            id: 2,
            category: "Giảng viên chuyên gia",
            title: "Giảng viên chuyên gia công nghệ",
            images: {
              image_1: "img/about-2.jpg",
              image_2: null,
            },
            description: `Giảng dạy các khóa đào tạo chuyên sâu về chuyển đổi số, công nghệ cho học viên là sinh viên, kỹ sư và nhân viên doanh nghiệp
              Xây dựng và phát triển nội dung khóa học phù hợp với nhu cầu thực tế của thị trường và các yêu cầu của doanh nghiệp trong quá trình chuyển đổi số
              Cập nhật và nâng cao chất lượng chương trình đào tạo theo xu hướng mới nhất trong lĩnh vực công nghệ và chuyển đổi số
              Tư vấn chuyên môn và hỗ trợ phát triển sản phẩm đào tạo nhằm đáp ứng yêu cầu của thị trường và khách hàng
              Tham gia nghiên cứu và phát triển các công cụ, tài liệu giảng dạy, bài tập thực hành, và các dự án học tập thực tiễn cho học viên
              Đánh giá hiệu quả đào tạo và đề xuất các giải pháp cải tiến để nâng cao chất lượng khóa học và kết quả học tập của học viên`,
            circles: [
              "Mức lương hấp dẫn, hoa hồng cao",
              "Chế độ đãi ngộ & phúc lợi tốt",
              "Cơ hội thăng tiến rõ ràng minh bạch",
              "Môi trường làm việc thân thiện, sáng tạo và chuyên nghiệp",
              "Được đào tạo chuyên sâu về chuyển đổi số & công nghệ",
              "Được đào tạo chuyên sâu về kỹ năng chuyên môn & giảng dạy",
            ],
            submit: {
              name: "Ứng tuyển",
              url: "/tuyen-dung#appointment",
            },
          },
        ],
      },
      appointment: {
        course: {
          name: "Đăng ký khóa học",
          headline: "Cơ hội bứt phá",
          title: "Hãy bắt đầu hành trình chuyển đổi số ngay hôm nay!",
          description: `Đừng bỏ lỡ cơ hội chuyển mình cùng công nghệ hiện đại, chúng tôi sẽ giúp bạn xây dựng và nâng cao kiến thức chuyển đổi số phù hợp, tối ưu hóa quá trình học tập và nâng cao hiệu quả làm việc tại doanh nghiệp trong thời đại số
          Đăng ký ngay để nhận tư vấn miễn phí, thông tin các khóa học và tìm ra giải pháp tối ưu cho việc học tập của bạn từ đội ngũ giảng viên chuyên gia giàu kinh nghiệm. Mong muốn đồng hành cùng bạn trên con đường phía trước!`,
          googleForms:
            '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeUOS0jMp7haQPfufqvEMwc3OUtTtLc6k10Upyjl1WtCDyjuQ/viewform?embedded=true" width="100%" height="1200" marginheight="0" marginwidth="0" title="Google Forms Appointment Course">Loading…</iframe>',
        },
        career: {
          name: "Ứng tuyển",
          headline: "Cơ hội bứt phá",
          title: "Cùng chúng tôi chuyển đổi số ngay hôm nay!",
          description: `Đừng bỏ lỡ cơ hội chuyển mình cùng công nghệ hiện đại, cùng chúng tôi xây dựng và nâng cao kiến thức chuyển đổi số phù hợp, tối ưu hóa quá trình học tập và nâng cao hiệu quả làm việc tại doanh nghiệp trong thời đại số
          Ứng tuyển ngay để cùng trao đổi về cơ hội nghề nghiệp và cùng khai phá thêm các giải pháp tối ưu về chuyển đổi số cùng đội ngũ giảng viên chuyên gia giàu kinh nghiệm. Mong muốn có bạn đồng hành trên con đường phía trước!`,
          googleForms:
            '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScvkwgc068T_Zo0PGqT-FXnTmdwj7eta94Az11N8GyM2NFk1w/viewform?embedded=true" width="100%" height="1200" marginheight="0" marginwidth="0" title="Google Forms Appointment Career">Loading…</iframe>',
        },
      },
      contactAddress: {
        name: "Địa chỉ",
        value: "Hà Nội, Việt Nam",
      },
      contactPhone: {
        name: "Điện thoại",
        value: "+84 86 888 0 797",
      },
      contactEmail: {
        name: "Thư điện tử",
        value: "HocVienChuyenDoiSoDoanhNghiep@gmail.com",
      },
      googleMaps: {
        hq: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.7292517570404!2d105.81704337548891!3d21.00348748063943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac848e7a6067%3A0x8b03cbf87b7af8ab!2zVG_DoCBuaMOgIFbDom4gTmFt!5e0!3m2!1svi!2s!4v1732122001719!5m2!1svi!2s",
      },
      contact: {
        name: "Liên hệ",
        headline: "Liên hệ chúng tôi",
        title: "Bạn có thắc mắc? Đừng ngần ngại! Liên hệ ngay!",
        description: `Nếu có bất kỳ câu hỏi gì về các khóa học chuyển đổi số, cần tư vấn thêm hay muốn tìm hiểu thêm về cách chúng tôi có thể giúp bạn phát triển bản thân, đừng ngần ngại liên hệ ngay`,
        form: {
          name: "Họ tên",
          email: "Email",
          mobile: "Số điện thoại",
          subject: "Tiêu đề",
          message: "Nội dung",
          message_placeholder: "Nội dung",
          submit: "Gửi tin nhắn",
        },
      },
      feature: {
        name: "Lợi thế vượt trội",
        headline: "Đáp ứng mọi nhu cầu & mong muốn",
        title: "Tại sao lựa chọn chúng tôi!",
        url: "/tinh-nang",
        description: `Luôn mang đến những giải pháp giảng dạy về chuyển đổi số toàn diện và linh hoạt, được thiết kế để đáp ứng nhu cầu riêng biệt của từng học viên
          Đội ngũ giảng viên chuyên gia giàu kinh nghiệm cam kết giúp tối ưu hóa quy trình giảng dạy, tăng hiệu quả và nâng cao kiến thức thực tế
          Sự thành công của học viên là ưu tiên hàng đầu và chúng tôi luôn sẵn sàng đồng hành trong mỗi bước đi giúp học viên phát triển bền vững`,
        list: [
          {
            class: "fa-user-md",
            name: "Chuyên môn & kinh nghiệm",
            value: "Giảng viên",
          },
          {
            class: "fa-check",
            name: "Chất lượng & thực tế",
            value: "Giáo trình",
          },
          {
            class: "fa-comment-medical",
            name: "Online & Offline",
            value: "Lịch học",
          },
          {
            class: "fa-headphones",
            name: "Liên tục 24/7",
            value: "Hỗ trợ",
          },
        ],
      },
      footer: {
        quickLinks: {
          name: "Liên kết",
          termsAndCondition: "Điều khoản",
          support: "Hỗ trợ",
        },
        newsletter: {
          name: "Đăng ký nhận email",
          description:
            "Tin tức mới nhất, ưu đãi đặc biệt hấp dẫn và các thông tin hữu ích các khóa học về chuyển đổi số",
          email: "Email",
          submit: "Đăng ký",
        },
        siteName: "",
        allRightReserved:
          "Bản quyền thuộc về học viện chuyển đổi số doanh nghiệp",
        design: {
          name: "",
          url: "",
          by: "",
        },
      },
      navBar: {
        name: "",
        title: "Chuyển đổi số doanh nghiệp",
      },
      notFound: {
        name: "404",
        title: "Trang không tồn tại",
        description:
          "Xin lỗi! Trang bạn tìm kiếm không tồn tại trên website của chúng tôi! Bạn có thể quay lại trang chủ hoặc thử sử dụng chức năng tìm kiếm?",
      },
      course: {
        page: "Khoá học",
        headline: "Công dân số",
        name: "Khóa học số",
        title: "Nâng kiến thức, tăng hiệu quả",
        url: "/khoa-hoc",
        outlines: [],
        list: [
          {
            id: "DATA_ANALYSIS",
            name: "Phân tích dữ liệu (Data Analysis)",
            credits: ["cDB_SQL", "cPBI", "cPYTHON_DATA"],
            price: null,
            discount: 30,
          },
          {
            id: "DATA_ENGINEER",
            name: "Tổng hợp, xử lý và phân tích dữ liệu (Data Engineer)",
            credits: ["cDB_SQL", "cDWH", "cPBI"],
            price: null,
            discount: 30,
          },
          {
            id: "DATA_SCIENCE",
            name: "Khoa học dữ liệu (Data Science)",
            credits: ["cDB_SQL", "cPYTHON_DATA", "cML_BASIC"],
          },
          {
            id: "DB_DESIGN",
            name: "Phân tích và thiết kế Database",
            credits: ["cDB_SQL", "cDB_DESIGN"],
          },
          {
            id: "REACTJS",
            name: "Lập trình FrontEnd với ReactJS",
            credits: ["cHTML", "cREACTJS"],
          },
          {
            id: "SPRINGBOOT",
            name: "Lập trình BackEnd Server với SpringBoot",
            credits: ["cJAVA", "cSPRINGBOOT"],
          },
          {
            id: "PYTHON_DJANGO",
            name: "Lập trình FullStack Website với Python Django",
            credits: ["cPYTHON_BASIC", "cPYTHON_ADVANCE", "cDJANGO"],
          },
          {
            id: "GOLANG",
            name: "Lập trình Golang từ cơ bản đến nâng cao",
            credits: ["cGOLANG", "cGOLANG_FRAMEWORK"],
          },
          {
            id: "DEVOPS",
            name: "DevOps từ cơ bản đến nâng cao",
            credits: ["cDOCKER", "cK8S", "cAWS_BASIC"],
          },
          {
            id: "AWS",
            name: "AWS Cloud Computing từ cơ bản đến nâng cao",
            credits: ["cAWS_BASIC", "cAWS_ADVANCE"],
          },
          {
            id: "AI",
            name: "Làm chủ AI: Trí tuệ nhân tạo dành cho tất cả mọi người",
            credits: ["cAI_CHATGPT_PRACTICE"],
          },
        ],
      },
      service: {
        page: "Dịch vụ",
        headline: "Xuất sắc vượt trội",
        name: "Dịch vụ số",
        title: "Tư vấn, đào tạo & triển khai",
        url: "/dich-vu",
        list: [
          {
            id: "SE_TRAIN",
            name: "Đào tạo nâng cao kiến thức & kỹ năng số",
            features: ["fx"],
          },
          {
            id: "SE_CONSULT",
            name: "Tư vấn chiến lược chuyển đổi số",
            features: ["fx"],
          },
          {
            id: "SE_EXECUTE",
            name: "Xây dựng và triển khai chuyển đổi số",
            features: ["fx"],
          },
          {
            id: "SX_SOFTWARE",
            name: "Phát triển hệ thống phần mềm website theo yêu cầu",
            features: ["fx"],
          },
          {
            id: "SX_STUDENT",
            name: "Hướng dẫn làm đồ án tốt nghiệp",
            features: ["fx"],
          },
        ],
      },
      product: {
        page: "Sản phẩm",
        headline: "Đơn giản mọi thứ",
        name: "Sản phẩm số",
        title: "Cải thiện năng suất & chất lượng",
        url: "/san-pham",
        list: [
          {
            id: "PRO_EMKT",
            name: "Email Marketing (EMKT)",
            features: ["fx"],
          },
          {
            id: "PRO_CRM",
            name: "Customer Relationship Management (CRM)",
            features: ["fx"],
          },
          {
            id: "PRO_ERP",
            name: "Enterprise Resource Planning (ERP)",
            features: ["fx"],
          },
        ],
      },
      team: {
        page: "Giảng viên chuyên gia",
        headline: "Lý luận thực tiễn",
        name: "Giảng viên chuyên gia",
        title: "Giàu chuyên môn & kinh nghiệm",
        url: "/giang-vien-chuyen-gia",
        list: [
          {
            id: 1,
            name: "Tan Do",
            department: "Head of Engineering",
            image: "img/team-tan.jpg",
          },
          {
            id: 2,
            name: "Duc Tran",
            department: "Senior Business Analyst",
            image: "img/team.jpg",
          },
          {
            id: 3,
            name: "Minh Le",
            department: "AWS Solution Architect",
            image: "img/team.jpg",
          },
          {
            id: 4,
            name: "Nam Nguyen",
            department: "Fullstack Developer",
            image: "img/team.jpg",
          },
        ],
      },
      testimonial: {
        name: "Cảm nhận học viên & khách hàng",
        headline: "Đánh giá & phản hồi",
        title: "Họ đang nói gì về chúng tôi!",
        list: [
          {
            id: 1,
            name: "Trang Hoang",
            title: "Data Engineer",
            image: "img/testimonial-1.jpg",
            description: `Sau khi hoàn thành khoá học, tôi đã có nền tàng vững chắc
              Hiểu rõ thế nào là Data Warehouse cũng như cách xây dựng, quản lý, vận hành cũng như lý do ra đời của nó
              Đây là những kiến thức & kỹ năng quan trọng giúp tôi nâng cao hiệu quả trong công việc`,
          },
          {
            id: 2,
            name: "Dung Truong",
            title: "Fullstack Developer",
            image: "img/testimonial-2.jpg",
            description: `Khóa học ở đây thực sự vô cùng hữu ích và rất thực tế 
              Tôi đã học, hiểu được cách vận dụng, tối ưu SQL để xử lý và phân tích dữ liệu một cách nhanh chóng và chính xác
              Các kiến thức đã học giúp tôi rất tự tin trong công việc và mở rộng cơ hội nghề nghiệp`,
          },
          {
            id: 3,
            name: "Hung Pham",
            title: "Project Manager",
            image: "img/testimonial-3.jpg",
            description: `Tôi đã tham gia các khoá học về cơ sở dữ liệu, lập trình và điện toán đám mây
              Những kiến thức thu được đã khai sáng trong tôi rất nhiều, làm rõ những vẫn đề mà các sản phẩm doanh nghiệp đang gặp phải
              Tôi đã áp dụng để tối ưu hóa quản lý tài nguyên và triển khai đạt hiệu quả và mang lại lợi ích rất lớn`,
          },
        ],
      },
      topBar: {},
      isp: {
        headline: "ISP",
        drives: ["1HY5ICXPHGNZ0C8RjCFGyjQr_Uldt_8Y8"],
      },
      tool: {
        headline: "Tools",
      },
    },
    modules: {
      credits: [
        {
          id: "cDB_SQL",
          class: "fa fa-database",
          name: "Ngôn ngữ SQL",
          price: 5000000,
          discount: 10,
          attend: 12250,
          outlines: [
            "01. Tổng quan Data, Database, RDBMS",
            "02. Basic SQL, Joins, Group, Having",
            "03. Advance SQL, Reporting & Analysis",
            "04. DML, DDL, Backup, Restore",
            "05. Lập trình T-SQL, PL/SQL",
            "06. View, Procedure, Function",
            "07. Schedule, Trigger, Job",
            "08. Index, Transaction, Deadlock",
            "09. Tuning SQL",
            "10. Review & Project",
          ],
          tags: ["#MySQL #PostgreSQL #SQLServer #Oracle"],
          requires: [],
        },
        {
          id: "cDB_DESIGN",
          class: "fa fa-database",
          name: "Thiết kế Database",
          price: 3000000,
          attend: 3368,
          outlines: [
            "01. Data Modelling",
            "02. Entity-Relationship Model, ACID, OLTP",
            "03. Chuẩn hóa dữ liệu 1NF, 2NF, 3NF",
            "04. Chuẩn hóa dữ liệu BCNF, 4NF, 5NF",
            "05. MultiDimensional Data Model, DWH, SITV, OLAP",
            "06. Review & Project",
          ],
          tags: [
            "#RDBMS #DataWarehouse #DatabaseDesign #NormalForm #NF #BoyceCodd",
          ],
          requires: ["cDB_SQL"],
        },
        {
          id: "cPYTHON_BASIC",
          class: "fa-brands fa-python",
          name: "Lập trình Python cơ bản",
          price: 4000000,
          attend: 10880,
          outlines: [
            "01. Tổng quan, cấu trúc chương trình, biến, kiểu dữ liệu & biểu thức",
            "02. Lệnh điều khiển IF, Vòng lặp LOOP, FOR, WHILE",
            "03. Kiểu dữ liệu String",
            "04. Kiểu dữ liệu Date Time",
            "05. Kiểu dữ liệu List",
            "06. Kiểu dữ liệu Tuple, Set",
            "07. Kiểu dữ liệu Dictionary",
            "08. Function, Module",
            "09. Xử lý File",
            "10. Review & Project",
          ],
          tags: ["#Python"],
        },
        {
          id: "cPYTHON_ADVANCE",
          class: "fa-brands fa-python",
          name: "Lập trình Python nâng cao",
          price: 3000000,
          attend: 3757,
          outlines: [
            "01. Hướng đối tượng, Class, Method, Attribute, Object, Getter & Setter",
            "02. Inheritance, Property",
            "03. Json, Base64, CSV",
            "04. RESTful API, thư viện requests",
            "05. Thư viện hệ thống os",
            "06. Thread, Process, Concurrency, Parallel",
            "07. Exception, Unit Test",
            "08. Review & Project",
          ],
          tags: ["#Python"],
          requires: ["cPYTHON_BASIC"],
        },
        {
          id: "cPYTHON_DATA",
          class: "fa-brands fa-python",
          name: "Trực quan hóa dữ liệu với Python",
          price: 5000000,
          attend: 3231,
          outlines: [
            "01. Tổng quan, cấu trúc chương trình, biến, kiểu dữ liệu & biểu thức",
            "02. Lệnh điều khiển IF, Vòng lặp LOOP, FOR, WHILE",
            "03. Kiểu dữ liệu String",
            "04. Kiểu dữ liệu Date Time",
            "05. Kiểu dữ liệu List",
            "06. Hướng đối tượng, Class, Method, Attribute, Object",
            "07. NumPy",
            "08. Pandas",
            "09. Matplotlib",
            "10. Seaborn",
            "11. SciPy",
            "12. Review & Project",
          ],
          tags: ["#Python"],
          requires: [],
        },
        {
          id: "cJAVA",
          class: "fa-brands fa-java",
          name: "Lập trình JAVA",
          price: 4000000,
          attend: 15999,
          outlines: [
            "01. Tổng quan, cấu trúc chương trình, biến, kiểu dữ liệu & biểu thức",
            "02. Lệnh điều khiển IF, SWITCH, Vòng lặp LOOP, FOR, WHILE",
            "03. Kiểu dữ liệu String",
            "04. Kiểu dữ liệu Date Time",
            "05. Kiểu dữ liệu Array",
            "06. Hướng đối tượng, Class, Method, Attribute, Object, Getter & Setter",
            "07. Kế thừa & Đa hình",
            "08. Trừu tượng & Đóng gói",
            "09. Collection & Generic",
            "10. Exception & Unit Test",
            "11. Thread, Process, Concurrency, Parallel",
            "12. Review & Project",
          ],
          tags: ["#Java"],
        },
        {
          id: "cSPRINGBOOT",
          class: "fa-solid fa-leaf",
          name: "SpringBoot Framework",
          price: 5000000,
          attend: 13233,
          outlines: [
            "01. Tổng quan về Spring, SpringMVC, SpringBoot",
            "02. SpringBoot Dependency Injection & Annotation",
            "03. Thymeleaf",
            "04. Spring JPA, JDBC & Hibernate",
            "05. Spring validation, upload files",
            "06. Spring security",
            "07. Spring interceptor",
            "08. Spring RESTful API",
            "09. Exception & Unit Test",
            "10. Cấu hình MyBatis vào SpringBoot",
            "11. Hướng dẫn deployment",
            "12. Review & Project",
          ],
          tags: ["#Java #SpringBoot"],
          requires: ["cJAVA"],
        },
        {
          id: "cHTML",
          class: "fa-brands fa-html5",
          name: "Lập trình HTML, CSS, JavaScript",
          price: 3000000,
          attend: 10400,
          outlines: [
            "01. Tổng quan Website & HTML cơ bản",
            "02. HTML nâng cao",
            "03. CSS cơ bản",
            "04. CSS nâng cao",
            "05. JavaScript cơ bản",
            "06. JavaScript nâng cao",
            "07. Xây dựng website thuần",
            "08. Bootstrap & các thành phần cơ bản",
            "09. Xây dựng website với bootstrap",
            "10. Review & Project",
          ],
          tags: ["#HTML #CSS #JavaScript"],
        },
        {
          id: "cREACTJS",
          class: "fa-brands fa-react",
          name: "Lập trình ReactJS",
          price: 4000000,
          attend: 4192,
          outlines: [
            "01. Tổng quan, cài đặt và khởi tạo project",
            "02. Quản lý state, props",
            "03. Truyền dữ liệu giữa các Components",
            "03. Tương tác với Form",
            "04. Routing",
            "05. Redux",
            "06. Hook",
            "07. Context API",
            "08. useEffect & axios, custom Hooks",
            "09. Nâng cao với useRef, useCallback, useMemo,...",
            "10. Review & Project",
          ],
          tags: ["#ReactJS"],
          requires: ["cHTML"],
        },
        {
          id: "cAWS_BASIC",
          class: "fa-brands fa-aws",
          name: "Amazon Web Services (AWS) cơ bản",
          price: 5000000,
          attend: 2252,
          outlines: [
            "01. Tổng quan Cloud Computing, AWS, Azure, GCP",
            "02. Pricing & Billing, IAM, EC2",
            "03. Virtual Private Cloud (VPC)",
            "04. Relational Database Services (RDS)",
            "05. Simple Storage Service (S3)",
            "06. Lambda Function",
            "07. API Gateway",
            "08. Elastic Load Balancer & Auto Scaling",
            "09. SES, SNS",
            "10. Review & Project",
          ],
          tags: ["#CloudComputing #AWS"],
        },
        {
          id: "cAWS_ADVANCE",
          class: "fa-brands fa-aws",
          name: "Amazon Web Services (AWS) nâng cao",
          price: 8000000,
          attend: 2252,
          outlines: [
            "01. Triển khai các servers trên EC2",
            "02. Thiết kế và triển khai hạ tầng với VPC",
            "03. Relational Database Services (RDS)",
            "04. Simple Storage Service (S3)",
            "05. Command Line Interface (CLI)",
            "06. Dynamo DB",
            "07. Cloudfront & Route53",
            "08. Lambda Function",
            "09. Cognito",
            "10. Cloud Watch & Cloud Trail",
            "11. ECR, ECS, Fargate",
            "12. SNS, SQS",
            "13. Kinesis",
            "14. Elastic Bean Stalk",
            "15. Cloud Formation",
            "16. Review & Project",
          ],
          tags: ["#CloudComputing #AWS"],
          requires: ["cAWS_BASIC"],
        },
        {
          id: "cDWH",
          class: "fa-solid fa-filter",
          name: "Data Warehouse",
          price: 5000000,
          attend: 1125,
          outlines: [
            "01. Tổng quan Data Warehouse, kiến trúc và các thành phần",
            "02. Thiết kế, xây dựng và triển khai Data Warehouse",
            "03. ETL, các thành phần và quy trình thực hiện",
            "04. Thực hiện transform phổ biến",
            "05. Các transform nâng cao",
            "06. Tổng hợp dữ liệu từ nhiều nguồn về Data Warehouse",
            "07. Schedule & Job",
            "08. Review & Project",
          ],
          tags: ["#DWH #ETL #SSIS #ODI"],
          requires: ["cDB_SQL"],
        },
        {
          id: "cPBI",
          class: "fa-solid fa-chart-simple",
          name: "Phân tích & trực quan hóa dữ liệu với Power BI",
          price: 4000000,
          attend: 3389,
          outlines: [
            "01. Tổng quan Power BI",
            "02. Power Query Editor",
            "03. Transform Data",
            "04. DAX (Data Analysis Expressions) cơ bản",
            "05. DAX (Data Analysis Expressions) nâng cao",
            "06. Visualizations bằng các biểu đồ",
            "07. Tạo báo cáo và Dashboard",
            "08. Review & Project",
          ],
          tags: ["#PowerBI"],
        },
        {
          id: "cDJANGO",
          class: "fa-brands fa-python",
          name: "Django Framework",
          price: 4000000,
          attend: 1988,
          outlines: [
            "01. Request, session, GET/POST method",
            "02. Admin panel và ORM của Django",
            "03. Customize form với html",
            "04. Tìm kiếm, phân trang",
            "05. Custom User Model và Base Template",
            "06. Mini project 3 RESTFUL service với DRF",
            "07. Bảo mật API với JWT",
            "08. CORS, ghép nối API với frontend",
            "09. Sử dụng git, deploy ứng dụng trên server",
            "10. Review & Project",
          ],
          tags: ["#Python #Django"],
          requires: ["cPYTHON_BASIC", "cPYTHON_ADVANCE", "cHTML"],
        },
        {
          id: "cDOCKER",
          class: "fa-brands fa-docker",
          name: "Docker",
          price: 4000000,
          attend: 1988,
          outlines: [
            "01. Tổng quan VM, Container, Docker, DockerHub, DevOps",
            "02. Cài đặt, kiến trúc và các lệnh cơ bản Docker",
            "03. Linux cơ bản",
            "04. Quản lý network & lưu trữ",
            "05. Docker Compose cơ bản",
            "06. Docker Compose nâng cao",
            "07. Docker Swarm",
            "08. Review & Project",
          ],
          tags: ["#Docker #DevOps"],
          requires: [],
        },
        {
          id: "cK8S",
          class: "fa-brands fa-docker",
          name: "Kubernetes (k8s)",
          price: 6000000,
          attend: 2919,
          outlines: [
            "01. Tổng quan về Docker, Kubernetes",
            "02. Deployment với Kubernetes",
            "03. Pod Container",
            "04. Scheduling trong K8S",
            "05. Deployment trong K8S",
            "06. Hệ thống lưu trữ trên K8S",
            "07. Quản lý thiết lập với Config",
            "08. K8S Network Architecture",
            "09. Helm và Prometheus",
            "10. K8S Scaling",
            "11. GitOps with ArgoCD",
            "12. Review & Project",
          ],
          tags: ["#k8s #Kubernetes #Docker #DevOps"],
          requires: ["cDOCKER"],
        },
        {
          id: "cGOLANG",
          class: "fa-brands fa-golang",
          name: "Lập trình Golang (Go)",
          price: 4000000,
          attend: 4898,
          outlines: [
            "01. Tổng quan, cấu trúc chương trình, biến, kiểu dữ liệu & biểu thức",
            "02. Lệnh điều khiển IF, Vòng lặp LOOP, FOR, WHILE",
            "03. Arrays và Slices",
            "04. Map",
            "05. Package & Function",
            "06. Struct & Phương thức",
            "07. Interface và Reflection",
            "08. Đọc và Ghi file",
            "09. Date Time",
            "10. Xử lý lỗi và Kiểm thử",
            "11. Goroutine và Channel",
            "12. Flow controls và defer",
            "13. Pointer, Struct, Slice và Map",
            "14. Method và Interface",
            "15. Review & Project",
          ],
          tags: ["#golang #go"],
          requires: [],
        },
        {
          id: "cGOLANG_FRAMEWORK",
          class: "fa-brands fa-golang",
          name: "Xây dựng API BackEnd Server với Golang Framework",
          price: 5000000,
          attend: 3813,
          outlines: [
            "01. Tổng quan Golang và các Framework phổ biến Gin, Fiber, Echo, Beego, ...",
            "02. Cài đặt, cấu hình & routing",
            "03. Middleware, Request & Response",
            "04. Template Rendering",
            "05. Validation & Binding",
            "06. Kết nối & thao tác Database",
            "07. Thao tác Database với GORM và sqlc",
            "08. Testing và Debugging",
            "09. File Upload",
            "11. Authentication & Authorization, JWT Token",
            "10. Response & Error Handling",
            "12. Review & Project",
          ],
          tags: ["#golang #go #GolangFramework #Gin #Fiber #Echo #Beego"],
          requires: ["cGOLANG"],
        },
        {
          id: "cAI_CHATGPT_PRACTICE",
          class: "fa-solid fa-brain",
          name: "ChatGPT thực chiến",
          price: 3000000,
          attend: 2243,
          outlines: [
            "01. Nhập môn ChatGPT",
            "02. Làm chủ công cụ viết AI",
            "03. Nhập môn midjourney AI",
            "04. ChatGPT và tạo âm thanh",
            "05. ChatGPT và sản xuất video",
            "06. ChatGPT và digital human",
            "07. ChatGPT nâng cao hiệu quả học tập",
            "08. ChatGPT nâng cao hiệu suất tại nơi làm việc",
            "09. Xu hướng của lĩnh vực trí tuệ nhân tạo",
            "10. Review & Project",
          ],
          tags: ["#AI #ChatGPT"],
          requires: [],
        },
        {
          id: "cML_BASIC",
          class: "fa-solid fa-brain",
          name: "Machine Learning cơ bản",
          price: 12000000,
          attend: 4433,
          outlines: [
            "01. Data preprocessing",
            "02. Simple Linear Regression",
            "03. Multiple Linear Regression",
            "04. Polynomial Linear Regression",
            "05. Support Vector Regression (SVR)",
            "06. Decision Tree Regression",
            "07. Random Forest Regression",
            "08. Evaluating Regression Models Performance",
            "09. Regression Model Selection",
            "10. Logistic Regression",
            "11. K-Nearest Neighbors (KNN)",
            "12. Support Vector Machine (SVM)",
            "13. Kernel SVM",
            "14. Naive Bayes",
            "15. Decision Tree Classification",
            "16. Random Forest Classification",
            "17. Classification Model Selection",
            "18. Evaluating Classification Models Performance",
            "19. K-Means Clustering",
            "20. Hierarchical Clustering",
            "21. Apriorl",
            "22. Eclat",
            "23. Upper Confidence Bound (UCB)",
            "24. Thompson Sampling",
            "25. Review & Project",
          ],
          tags: ["#AI #ML #MachineLearning"],
          requires: ["cPYTHON_BASIC", "cPYTHON_ADVANCE"],
        },
      ],
      features: [
        {
          id: null,
          class: "fa-solid fa-heart-pulse",
          name: null,
          outlines: [],
        },
        {
          id: null,
          class: "fa-solid fa-brain",
          name: null,
          outlines: [],
        },
        {
          id: null,
          class: "fa-solid fa-handshake",
          name: null,
          outlines: [],
        },
      ],
    },
  };
}
