export default function ContactEmail({ data }) {
  const component = data?.components?.contactEmail;

  return (
    <>
      <div
        className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
        style={{ width: "55px", height: "55px" }}
      >
        <a href={`mailto:${component?.value}`}>
          <i className="fa fa-envelope-open text-primary"></i>
        </a>
      </div>
      <div className="ms-4">
        <a href={`mailto:${component?.value}`}>
          <p className="mb-2">{component?.name}</p>
          <h5
            className="mb-0"
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            {component?.value}
          </h5>
        </a>
      </div>
    </>
  );
}
