import PageHeader from "../components/PageHeader";
import Team from "../components/Team";

export default function TeamPage({ data }) {
  return (
    <>
      <PageHeader headline={data?.components?.team?.headline} />
      <Team data={data} />
    </>
  );
}
