export default function ContactPhone({ data }) {
  const component = data?.components?.contactPhone;

  return (
    <>
      <div
        className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
        style={{ width: "55px", height: "55px" }}
      >
        <a href={`tel:${component?.value?.replace(/\s+/g, "")}`}>
          <i className="fa fa-phone-alt text-primary"></i>
        </a>
      </div>
      <div className="ms-4">
        <a href={`tel:${component?.value?.replace(/\s+/g, "")}`}>
          <p className="mb-2">{component?.name}</p>
          <h5 className="mb-0">{component?.value}</h5>
        </a>
      </div>
    </>
  );
}
