import Fragment from "./Fragment";
import { formatNumber, calcTotalModulePrice } from "../utils/commonUtil";

export default function Module({ data, modules }) {
  const allCredits = data?.modules?.credits;
  return (
    <>
      {/* {console.log(modules)} */}
      {/* <div>{JSON.stringify(modules)}</div> */}
      {modules?.map(
        (item) =>
          item.id && (
            <div
              key={item.id}
              title="Tín chỉ"
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light rounded h-100 p-5 d-flex flex-column">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle"
                    style={{ width: "65px", height: "65px", flexShrink: 0 }}
                  >
                    <i className={`${item?.class} text-primary fs-4`}></i>
                  </div>
                  <h4 className="ms-3">{item?.name}</h4>
                </div>
                {item?.outlines && (
                  <Fragment
                    title={"Bài giảng"}
                    description={item?.outlines}
                    pClassName={"mb-4"}
                    iClassName={
                      "fa-solid fa-person-chalkboard text-primary me-3"
                    }
                  />
                )}

                <div className="mt-auto">
                  {item?.requires && (
                    <Fragment
                      title={"Yêu cầu Tín chỉ"}
                      description={allCredits
                        ?.filter((i) => item?.requires?.includes(i?.id))
                        .map((i) => i?.name)}
                      pClassName={"mb-4"}
                      iClassName={
                        "fa-solid fa-circle-exclamation text-danger me-3"
                      }
                    />
                  )}

                  {item?.tags && (
                    <Fragment
                      description={item?.tags}
                      pClassName={"mb-4"}
                      iClassName={"fa-solid fa-tag text-success me-3"}
                    />
                  )}

                  {item?.attend && (
                    <p className="btn me-1" title={"Học viên"}>
                      <i className="fa-solid fa-user text-primary me-3"></i>
                      {formatNumber({ number: item?.attend })}
                    </p>
                  )}

                  {item?.price && (
                    <p className="btn me-1" title={"Học phí"}>
                      <i className="fa-solid fa-sack-dollar text-primary me-3"></i>
                      {calcTotalModulePrice({ module: item })}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
}
