export default function TopBar({ data }) {
  return (
    <div
      className="container-fluid bg-light p-0 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="row gx-0 d-none d-lg-flex">
        <div className="col-lg-7 px-5 text-start">
          <div className="h-100 d-inline-flex align-items-center py-3 me-4">
            <small className="fa fa-map-marker-alt text-primary me-2"></small>
            <small>{data?.components?.contactAddress?.value}</small>
          </div>
          <div className="h-100 d-inline-flex align-items-center py-3">
            <small className="far fa-clock text-primary me-2"></small>
            <small>{data?.master?.workingHours}</small>
          </div>
        </div>
        <div className="col-lg-5 px-5 text-end">
          <div className="h-100 d-inline-flex align-items-center py-3 me-4">
            <a
              href={`tel:${data?.components?.contactPhone?.value?.replace(
                /\s+/g,
                ""
              )}`}
            >
              <small className="fa fa-phone-alt text-primary me-2"></small>
              {data?.components?.contactPhone?.value}
            </a>
          </div>
          <div className="h-100 d-inline-flex align-items-center">
            <a
              className="btn btn-sm-square rounded-circle bg-white text-primary me-1"
              href={data?.master?.socialNetwork?.facebook}
              target="blank"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className="btn btn-sm-square rounded-circle bg-white text-primary me-1"
              href={data?.master?.socialNetwork?.youtube}
              target="blank"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
