import React from "react";

export default function Fragment({
  description,
  pClassName,
  iClassName,
  title,
}) {
  const list = Array.isArray(description)
    ? description
    : description?.split("\n") || [];
  return (
    <>
      {list?.map(
        (line, index) =>
          line &&
          line.trim() !== "" && (
            <React.Fragment key={index}>
              <p
                title={title}
                className={
                  pClassName && pClassName.trim() !== "" ? pClassName : ""
                }
                // dangerouslySetInnerHTML={{ __html: line }}
              >
                <i
                  className={
                    iClassName && iClassName.trim() !== "" ? iClassName : ""
                  }
                ></i>
                {line}
              </p>
            </React.Fragment>
          )
      )}
    </>
  );
}
